import Splide from '@splidejs/splide'
import gsap from 'gsap'
import {
  debounce,
  killScrollTrigger,
  refreshScrollTrigger,
} from '../../assets/scripts/util/functional'
import Global from '../../assets/scripts/inc/global'

import ScrollTrigger from 'gsap/ScrollTrigger'

const { breakpoints } = Global

const Component = {
  init() {
    this.caseStudies()
  },
  caseStudies() {
    const caseStudies = $('.js-case-studies')
    if (caseStudies.length) {
      const getMaxItemsHeight = $items => {
        $items.css('height', '')

        let maxItemHeight = 0
        $items.each(function () {
          const $this = $(this)
          const elHeight = $this.outerHeight()

          if (elHeight > maxItemHeight) {
            maxItemHeight = elHeight
          }
        })

        $items.css('height', maxItemHeight)

        return maxItemHeight
      }

      caseStudies.each(function () {
        const $caseStudy = $(this)
        const $slider = $caseStudy.find('.splide.js-case-studies-slider')
        const $caseStudiesHeading = $caseStudy.find('.js-case-studies-heading')
        const $caseStudyItems = $caseStudy.find('.js-case-study')
        const slideCount = $slider.find('.splide__slide').length
        $slider.find('.splide__track').addClass('slide-count-' + slideCount)

        const caseStudySlider = new Splide($slider[0], {
          type: 'fade',
          drag: true,
          arrows: false,
          pagination: true,
          perPage: 1,
          //omitEnd: true,
          // rewind: true,
          wheel: true,
          wheelSleep: 400,
          releaseWheel: true,
          // destroy: true,
        })

        const activateSlider = () => {
          if ($(caseStudySlider.root).hasClass('is-active')) {
            caseStudySlider.destroy()
          }
          caseStudySlider
            .on('click', function (Slide) {
              caseStudySlider.go(Slide.index)
            })
            .on('pagination:updated', function (data, prev, curr) {
              if (curr.page + 1 === slideCount) {
                $slider
                  .find('.splide__slide:first-child')
                  .addClass('is-next-slide')
              } else if ($slider.find('.splide__slide.is-next-slide').length) {
                $slider
                  .find('.splide__slide.is-next-slide')
                  .removeClass('is-next-slide')
              }
            })
            .mount()
        }

        //activateSlider()

        let tl, tl2

        const animateItems = () => {
          const headerHeight = $('.header').outerHeight() + 10
          const scrollTriggerArgs = {
            trigger: $caseStudy.find('.js-case-studies-inner')[0],
            start: 'top top+=' + headerHeight,
            end:
              '+=' +
              (headerHeight +
                $caseStudy.outerHeight() * $caseStudyItems.length * 1.5),
            scrub: 2,
          }

          tl = gsap.timeline({
            scrollTrigger: {
              ...scrollTriggerArgs,
              pin: true,
              pinnedContainer: $caseStudy[0],
            },
            transformOrigin: 'top center',
          })

          tl2 = gsap.timeline({
            scrollTrigger: scrollTriggerArgs,
            transformOrigin: 'top center',
          })

          let offset = 20

          if ($(window).width() <= breakpoints.lg) {
            offset = 12
          }
          $caseStudyItems.each(function (index, el) {
            if (index !== 0) {
              tl.fromTo(
                el,
                {
                  y: 200 * index + '%',
                },
                {
                  y: index * offset + 'px',
                  duration: 1,
                }
              )
            }

            tl2.fromTo(
              el,
              {
                scale: 1,
              },
              {
                scale: 1 - ($caseStudyItems.length - (index + 1)) * 0.04,
                //duration: 3,
              }
            )
          })

          debounce(() => {
            refreshScrollTrigger(ScrollTrigger)
          }, 1000)
        }

        const toggleAnimation = () => {
          const maxItemsHeight = getMaxItemsHeight($caseStudyItems)

          const sliderHeight =
            maxItemsHeight + $caseStudiesHeading.outerHeight()
          const windowHeight = $(window).height() - $('.header').outerHeight()

          if (
            sliderHeight < windowHeight &&
            !$slider.hasClass('is-gsap-scroll')
          ) {
            if ($(caseStudySlider.root).hasClass('is-active')) {
              caseStudySlider.destroy()
            }
            $slider
              .removeClass('is-splide')
              .addClass('is-gsap-scroll')
              .css('height', maxItemsHeight)

            animateItems()
          } else if (
            sliderHeight >= windowHeight &&
            !$slider.hasClass('is-splide')
          ) {
            killScrollTrigger(tl)
            killScrollTrigger(tl2)

            $slider
              .removeClass('is-gsap-scroll')
              .addClass('is-splide')
              .css('height', '')

            activateSlider()
            // debounce(activateSlider, 1000)
          } else if (
            sliderHeight < windowHeight &&
            $slider.hasClass('is-gsap-scroll')
          ) {
            $slider.css('height', maxItemsHeight)
            ScrollTrigger.refresh()
          }
        }

        toggleAnimation()

        $(window).on('resize load', function () {
          debounce(toggleAnimation, 2000)
        })
      })
    }
  },
}

$(() => {
  Component.init()
})
